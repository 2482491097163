import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Sidebar, Icon, Segment, Menu } from "semantic-ui-react";
import { signout } from "../../store/actions/authActions";
import { update } from "../../store/actions/localActions";

class MenuRow extends Component {
  render() {
    const {
      title,
      icon,
      type,
      onClick,
      groupId,
      updateLocal,
      onHide,
    } = this.props;
    return (
      <div
        key={title}
        className={`menu-row${type === "noHover" ? " noHover" : ""}${
          type === "subMenu" ? " noIcon" : ""
        }`}
        onClick={onClick}
      >
        {type !== "subMenu" ? (
          <div className="menu-row-icon">
            <Icon name={icon} size="large" />
          </div>
        ) : null}
        <div className="menu-row-text">{title}</div>
        {type == "menu" ? (
          <>
            <div className="menu-row-icon">
              <Icon name="angle right" size="large" />
            </div>
            <SubMenu
              groupId={groupId}
              updateLocal={updateLocal}
              onHide={onHide}
            />
          </>
        ) : null}
      </div>
    );
  }
}

MenuRow.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  admin: PropTypes.bool,
  groupId: PropTypes.string,
  updateLocal: PropTypes.func,
  onHide: PropTypes.func,
};

class SubMenu extends Component {
  render() {
    const { updateLocal, onHide, groupId } = this.props;
    return (
      <div className="sub-menu">
        <MenuRow type="noHover" />
        <MenuRow
          key={groupId + "-Home"}
          title="Home"
          type="subMenu"
          onClick={() => {
            onHide();
            updateLocal({ currentTab: "home" });
          }}
        />
        <MenuRow
          key={groupId + "-User"}
          title="Users"
          type="subMenu"
          onClick={() => {
            onHide();
            updateLocal({ currentTab: "users" });
          }}
        />
      </div>
    );
  }
}

SubMenu.propTypes = {
  updateLocal: PropTypes.func,
  onHide: PropTypes.func,
  groupId: PropTypes.string,
};

class SideBarWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      children,
      showSideBar,
      onHide,
      user,
      signout,
      updateLocal,
    } = this.props;

    const _activeGroup = localStorage.getItem("activeGroup");

    return (
      <Sidebar.Pushable as={Segment}>
        <Sidebar
          as={Menu}
          animation="overlay"
          icon="labeled"
          vertical
          onHide={() => onHide()}
          visible={showSideBar}
          width="wide"
        >
          <div className="sidebar-top">
            <MenuRow icon="close" onClick={() => onHide()} />
            <MenuRow
              icon="home"
              key="Home"
              title="Home"
              onClick={() => {
                onHide();
                updateLocal({ currentTab: "home" });
              }}
            />
            {user.isLeader(_activeGroup) ? (
              <MenuRow
                icon="users"
                key="Users"
                title="Users (Beta)"
                onClick={() => {
                  onHide();
                  updateLocal({ currentTab: "users" });
                }}
              />
            ) : null}
            {user.isGroupAdmin(_activeGroup) ? (
              <MenuRow
                icon="add to calendar"
                key="Events"
                title="Events"
                onClick={() => {
                  onHide();
                  updateLocal({ currentTab: "events" });
                }}
              />
            ) : null}
            {user.admin ? (
              <MenuRow
                icon="server"
                title="Jobs"
                onClick={() => {
                  onHide();
                  updateLocal({
                    currentTab: "jobs",
                  });
                }}
              />
            ) : null}
          </div>
          <div className="sidebar-bottom">
            <MenuRow
              title="Settings"
              icon="cog"
              onClick={() => {
                onHide();
                updateLocal({
                  currentTab: "settings",
                });
              }}
            />
            <MenuRow
              title="Sign Out"
              icon="sign-out"
              onClick={() => {
                onHide();
                signout();
              }}
            />
          </div>
        </Sidebar>

        <Sidebar.Pusher style={{ height: "100%" }}>{children}</Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateLocal: (data) => dispatch(update(data)),
    signout: (data) => dispatch(signout(data)),
  };
};

SideBarWrapper.propTypes = {
  children: PropTypes.array,
  showSideBar: PropTypes.bool,
  onHide: PropTypes.func,
  groups: PropTypes.array,
  user: PropTypes.object,
  history: PropTypes.object,
  signout: PropTypes.func,
  updateLocal: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(SideBarWrapper);
