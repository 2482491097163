import React, { Component } from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import ImageFadeIn from "react-image-fade-in";

class SCard extends Component {
  constructor(props) {
    super(props);
    this.state = { imageUrl: null };
  }

  render() {
    const { title, subtitle, style, width, height, imageRef, storage, onClick } = this.props;

    const defaultStyle = { width, height };
    const cardStyle = Object.assign({}, style, defaultStyle);

    return (
      <div className="stack-card" style={cardStyle} onClick={onClick}>
        {storage && storage[imageRef] ? (
          <ImageFadeIn
            src={storage[imageRef]}
            width={width}
            height={height}
          />
        ) : (
          <div>
            <Skeleton height={height} width={width} />
          </div>
        )}
        <div className="shade" />
        <div className="stack-card-text">
          <div className="stack-card-title">{title}</div>
          <div className="stack-card-subtitle">{subtitle}</div>
        </div>
      </div>
    );
  }
}

SCard.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  style: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onClick: PropTypes.func,
  imageRef: PropTypes.string,
  storage: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    storage: state.storage,
  };
};

export const TestCard = connect(mapStateToProps, null)(SCard);

class StackCard extends Component {
  render() {
    return <TestCard {...this.props} />;
  }
}

export default StackCard;
