const firebaseConfig_DEV = {
  apiKey: "AIzaSyBRiVLaA1F7Gv72jnwzNuA7989mVsuRCu8",
  authDomain: "rhccc-development.firebaseapp.com",
  databaseURL: "https://rhccc-development.firebaseio.com",
  projectId: "rhccc-development",
  storageBucket: "rhccc-development.appspot.com",
  messagingSenderId: "732579032602",
  appId: "1:732579032602:web:468d0e6388ff3db55b6ba3",
  measurementId: "G-VQBEPWY6C4",
};

const firebaseConfig_PROD = {
  apiKey: "AIzaSyDp-qdBkkM0JZX2KbGMTjiQTlKADQQCk2Q",
  authDomain: "rhccc-324f1.firebaseapp.com",
  databaseURL: "https://rhccc-324f1.firebaseio.com",
  projectId: "rhccc-324f1",
  storageBucket: "rhccc-324f1.appspot.com",
  messagingSenderId: "619878546162",
  appId: "1:619878546162:web:c0ae46b901c944291aae6b",
  measurementId: "G-FPQX4F083H",
};

export { firebaseConfig_DEV, firebaseConfig_PROD };
