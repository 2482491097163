const initState = {};

const storageReducer = (state = initState, action) => {
  switch (action.type) {
    case "UPDATE_IMAGE":
      return Object.assign({}, state, action.data);
    default:
      return state;
  }
};

export default storageReducer;
