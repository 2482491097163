import React, { Component } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { isLoaded, isEmpty } from "react-redux-firebase";
import { withRouter } from "react-router-dom";
import { createMedia } from "@artsy/fresnel";
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Image,
  Menu,
  Segment,
  Sidebar,
  Visibility,
} from "semantic-ui-react";

import User from "FirebaseObj/user";
import BottomSection from "../sharedComponents/BottomSection.js";
import NavigationBar from "../sharedComponents/NavigationBar.js";
import welcomePageImage from "Images/pointing_at_monitor.jpg";
import marketing from "Images/marketing.png";
import feedback from "Images/feedback.svg";

const AppMedia = createMedia({
  breakpoints: {
    mobile: 320,
    tablet: 768,
    computer: 992,
    largeScreen: 1200,
    widescreen: 1920,
  },
});
const mediaStyles = AppMedia.createMediaStyle();
const { Media, MediaContextProvider } = AppMedia;

const HomeHeading = ({ mobile, history }) => (
  <Container className="welcomepage-heading" text>
    <Header
      as="h1"
      content="FELLA"
      inverted
      style={{
        fontSize: mobile ? "3em" : "4em",
        fontWeight: "bold",
        marginBottom: 0,
        marginTop: mobile ? "0.5em" : "3em",
      }}
    />
    <Header
      as="h2"
      content="Fellowship Life Starts Here"
      inverted
      style={{
        fontSize: mobile ? "1.5em" : "1.7em",
        fontWeight: "normal",
        marginTop: mobile ? "0.3em" : "0.5em",
      }}
    />
    <Header
      as="h3"
      content="Keep connected with other members and stay updated with latest news of your favourite group"
      inverted
      style={{
        fontSize: mobile ? "1.0em" : "1.0em",
        marginTop: mobile ? "0.5em" : "1.0em",
      }}
    />
    <Button
      primary
      size="huge"
      onClick={() => {
        // Dsahboard is a private route and it will direct users to sign in page if not authenticated
        history.push("/home");
      }}
    >
      Get Started
      <Icon name="right arrow" />
    </Button>
  </Container>
);

HomeHeading.propTypes = {
  mobile: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  history: PropTypes.object,
};

const WelcomepageHeading = withRouter(HomeHeading);

class DesktopContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "Home",
    };
  }

  hideFixedMenu = () => this.setState({ fixed: false });
  showFixedMenu = () => this.setState({ fixed: true });

  render() {
    const { children, isAuthenticated } = this.props;
    const { fixed } = this.state;

    return (
      <div>
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <Segment
            inverted
            textAlign="center"
            style={{ minHeight: 700, padding: "0.1em 0em" }}
            vertical
          >
            <div
              className="welcomepage-image"
              style={{ position: "absolute", width: "100%" }}
            >
              <img src={welcomePageImage} height={700} />
            </div>
            <NavigationBar fixed={fixed} inverted={!fixed} showLogo={fixed} showActions/>
            <WelcomepageHeading isAuthenticated={isAuthenticated} />
          </Segment>
        </Visibility>
        {children}
      </div>
    );
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.array,
  isAuthenticated: PropTypes.bool,
  currentUser: PropTypes.object,
};

class MobileContainer extends Component {
  state = {};

  handleSidebarHide = () => this.setState({ sidebarOpened: false });
  handleToggle = () => this.setState({ sidebarOpened: true });

  render() {
    const { children } = this.props;
    const { sidebarOpened, fixed } = this.state;

    return (
      <Sidebar.Pushable as={Media} at="mobile">
        <Sidebar
          as={Menu}
          animation="push"
          inverted
          onHide={this.handleSidebarHide}
          vertical
          visible={sidebarOpened}
        >
          <Menu.Item as="a" active>
            Home
          </Menu.Item>
          <Menu.Item as="a">Log in</Menu.Item>
          <Menu.Item as="a">Activate</Menu.Item>
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment
            inverted
            textAlign="center"
            style={{ minHeight: 350, padding: 0 }}
            vertical
          >
            <div className="welcomepage-image" style={{ position: "absolute" }}>
              <img src={welcomePageImage} height={350} />
            </div>
            <Container>
              <NavigationBar fixed={fixed} inverted={!fixed} showLogo={fixed} />
            </Container>
            <WelcomepageHeading mobile />
          </Segment>
          {children}
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  }
}

MobileContainer.propTypes = {
  children: PropTypes.array,
};

function ResponsiveContainer({ children }) {
  const auth = useSelector((state) => state.firebase.auth);
  const isAuthenticated = isLoaded(auth) && !isEmpty(auth);

  const profile = useSelector((state) => state.firebase.profile);
  const currentUser = User.fromJson(profile);

  return (
    <div>
      <DesktopContainer
        isAuthenticated={isAuthenticated}
        currentUser={currentUser}
      >
        {children}
      </DesktopContainer>
      <MobileContainer
        isAuthenticated={isAuthenticated}
        currentUser={currentUser}
      >
        {children}
      </MobileContainer>
    </div>
  );
}

ResponsiveContainer.propTypes = {
  children: PropTypes.array,
};

const WelcomepageLayout = ({ history }) => (
  <>
    <style>{mediaStyles}</style>
    <MediaContextProvider>
      <ResponsiveContainer>
        <Segment style={{ padding: "8em 0em" }} vertical>
          <Grid container stackable verticalAlign="middle">
            <Grid.Row>
              <Grid.Column width={8}>
                <Header as="h3" style={{ fontSize: "2em" }}>
                  We Help Churches and Groups to Grow
                </Header>
                <p style={{ fontSize: "1.33em" }}>
                  Fella is a group management online platform for leaders to
                  administer and provide latest updates and upcoming events
                  information to members
                </p>
                <Header as="h3" style={{ fontSize: "2em" }}>
                  Real-Time Dashboard & Analytical Insights
                </Header>
                <p style={{ fontSize: "1.33em" }}>
                  We offers a wide-range of data analytical dashboards to
                  analyze group trends and provide statistics on the health of
                  your groups
                </p>
              </Grid.Column>
              <Grid.Column floated="right" width={8}>
                <Image size="massive" src={marketing} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Segment style={{ padding: "8em 0em" }} vertical>
          <Grid container stackable verticalAlign="middle">
            <Grid.Row>
              <Grid.Column width={8}>
                <Image size="large" src={feedback} />
              </Grid.Column>
              <Grid.Column width={8}>
                <Header as="h3" style={{ fontSize: "2em" }}>
                  Missing some features?
                </Header>
                <p style={{ fontSize: "1.33em" }}>
                  {
                    "Fella's mission is to offer a community platform and promote user engagement. \
                We are here to listen and your valuable feedback is most important to us!"
                  }
                </p>
                <Button
                  as="a"
                  size="large"
                  onClick={() => {
                    history.push("/feedback");
                  }}
                >
                  {"Let Us Know"}
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <BottomSection />
      </ResponsiveContainer>
    </MediaContextProvider>
  </>
);

WelcomepageLayout.propTypes = {
  history: PropTypes.object,
};

export default withRouter(WelcomepageLayout);
