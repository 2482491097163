import firebase from "firebase/app";

export async function getDownloadUrl(path) {
  return firebase.storage().ref().child(path).getDownloadURL();
}

export function getImage(path) {
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    const state = getState();
    if (!path || state.storage[path]) return;

    const url = await firebase.storage().ref().child(path).getDownloadURL();
    dispatch({ type: "UPDATE_IMAGE", data: { [path]: url } });
  };
}

export function upload(path, files, dbPath, options) {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    return firebase
      .uploadFiles(path, files, dbPath, options)
      .then((snapshots) => {
        // Once the file is uploaded, we will download the url
        // and store it into the redux store
        snapshots.forEach(async (snap) => {
          const ref = snap.uploadTaskSnapshot.ref;
          const url = await ref.getDownloadURL();
          dispatch({ type: "UPDATE_IMAGE", data: { [ref.fullPath]: url } });
        });
      });
  };
}