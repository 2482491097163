import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import scrollIntoView from "scroll-into-view";
import PropTypes from "prop-types";

const AUTO_SCROLL_TIME = 3000;

class ScrollView extends Component {
  constructor(props) {
    super(props);
    this.state = { index: 0 };

    if (props.autoScroll) {
      this.timer = setInterval(() => {
        this.scrollIndex(
          this.state.index === Object.keys(this.elements).length - 1
            ? 0
            : this.state.index + 1
        );
      }, AUTO_SCROLL_TIME);
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  static childContextTypes = {
    scroll: PropTypes.object,
  };
  elements = {};

  register = (index, ref) => {
    this.elements[index] = ref;
  };

  unregister = (index) => {
    delete this.elements[index];
  };

  getChildContext() {
    return {
      scroll: {
        register: this.register,
        unregister: this.unregister,
      },
    };
  }

  scrollIndex = (newIndex) => {
    // eslint-disable-next-line react/no-find-dom-node
    const node = findDOMNode(this.elements[newIndex]);
    scrollIntoView(node, {
      time: 500,
      align: {
        left: 0,
      },
      validTarget: function (target, parentsScrolled) {
        // Only scroll the first two elements that don't have the class "dontScroll"
        // Element.matches is not supported in IE11, consider using Element.prototype.msMatchesSelector if you need to support that browser
        return (
          parentsScrolled < 2 &&
          target !== window &&
          !target.matches(".dontScroll")
        );
      },
      isScrollable: () => true,
    });

    this.setState({ index: newIndex });
  };

  scrollToFirst = () => {
    this.scrollIndex(0);
  };

  scrollRight = () => {
    const newIndex = Math.min(
      Math.max(...Object.keys(this.elements).map((i) => parseInt(i))),
      this.state.index + 1
    );
    this.scrollIndex(newIndex);
  };

  scrollLeft = () => {
    const newIndex = Math.max(0, this.state.index - 1);
    this.scrollIndex(newIndex);
  };

  render() {
    return React.Children.only(this.props.children);
  }
}

ScrollView.propTypes = {
  children: PropTypes.object,
  autoScroll: PropTypes.bool,
};

class ScrollElement extends Component {
  static contextTypes = {
    scroll: PropTypes.object,
  };

  componentDidMount() {
    this.context.scroll.register(this.props.index, this._element);
  }

  componentWillUnmount() {
    this.context.scroll.unregister(this.props.index);
  }

  render() {
    return React.cloneElement(this.props.children, {
      ref: (ref) => (this._element = ref),
      style: this.props.style,
    });
  }
}

ScrollElement.propTypes = {
  index: PropTypes.number,
  children: PropTypes.object,
  style: PropTypes.object,
};

export { ScrollElement };
export default ScrollView;
