import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import ImageFadeIn from "react-image-fade-in";
import { Icon, Button } from "semantic-ui-react";

class ProgramCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  addToCalendar(p) {
    const GOOGLE_DATE_FORMAT = "YYYYMMDDTHHmmSS";

    const title = p.title.replace(' ', '+');
    const detail = encodeURI(p.detail);
    const address = p.address.replace(' ', '+');
    const startDate = moment(p.startDate).format(GOOGLE_DATE_FORMAT);
    const endDate = moment(p.endDate).format(GOOGLE_DATE_FORMAT);

    const googleCalendarUrl = 'https://calendar.google.com/calendar/u/0/r/eventedit';
    const googleCalendarOptions = `?text=${title}&dates=${startDate}/${endDate}&location=${address}&trp=false&details=${detail}&sf=true`;
    window.open(googleCalendarUrl + googleCalendarOptions);
  }

  render() {
    const {
      event,
      width,
      height,
      onClick,
      storage,
    } = this.props;

    return (
      <div className="program-card" style={{ height }} onClick={onClick}>
        <div className="program-card-text">
          <div className="title">{event.title}</div>
          <div className="subtitle">
            <Icon name="calendar alternate outline" />
            {moment(event.startDate).format("MMM DD, YYYY (ddd)")}
          </div>
          {event.location || event.room ? (
            <div className="location">
              <Icon name="map pin" />
              {`${event.location ? event.location : ""}${
                event.room ? `${event.location ? "-" : ""}${event.room}` : ""
              }`}
            </div>
          ) : null}
          <div className="detail">{`${event.detail}`}</div>
          <Button primary onClick={(e) => {
            this.addToCalendar(event);
            e.stopPropagation();
          }}>
            <Icon name="calendar alternate" /> Add To Calendar
          </Button>
        </div>
        <div className="program-card-image">
          {event.imageRef && storage[event.imageRef]? (
            <ImageFadeIn
              src={storage[event.imageRef]}
              height={height}
              width={width}
            />
          ) : (
            <Skeleton height={height} width={width} />
          )}
        </div>
      </div>
    );
  }
}

ProgramCard.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  style: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  imageRef: PropTypes.string,
  location: PropTypes.string,
  detail: PropTypes.string,
  room: PropTypes.string,
  onClick: PropTypes.func,
  storage: PropTypes.object,
  event: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    storage: state.storage,
  };
};

export default connect(mapStateToProps, null)(ProgramCard);
