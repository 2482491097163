import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Segment, Container, Grid, Header, List } from "semantic-ui-react";
import apple from "Images/apple_app_store.png";
import android from "Images/google_play_badge.png";

class BottomSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Segment className="bottom-section" vertical>
        <Container>
          <Grid divided stackable>
            <Grid.Row>
              <Grid.Column width={3}>
                <Header as="h4" content="About" />
                <List link>
                  <List.Item as="a">Contact Us</List.Item>
                  <List.Item
                    as="a"
                    onClick={() => {
                      this.props.history.push("/privacy_policy");
                    }}
                  >
                    Privacy Policy
                  </List.Item>
                  <List.Item
                    as="a"
                    onClick={() => {
                      this.props.history.push("/terms_and_conditions");
                    }}
                  >
                    Terms and Conditions
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={4}>
                <Header as="h4">Mobile App</Header>
                <div>
                  <a href="https://itunes.apple.com/us/app/facebook/id1441220855?mt=8">
                    <img
                      src={apple}
                      alt="Download on the App Store"
                      style={{ height: 48, paddingRight: 10 }}
                    />
                  </a>
                </div>
                <div>
                  <a href="https://play.google.com/store/apps/details?id=com.ivanchau.rhcccsystem&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                    <img
                      src={android}
                      alt="Get it on Google Play"
                      style={{ width: 186, marginLeft: -12 }}
                    />
                  </a>
                </div>
              </Grid.Column>
              {/* <Grid.Column width={4}>
                <Header as="h4">Donation Support</Header>
                <a
                  className="bmc-button"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.buymeacoffee.com/kodaar"
                >
                  <img
                    src="https://cdn.buymeacoffee.com/buttons/bmc-new-btn-logo.svg"
                    alt="Buy me a coffee"
                  />
                  <span>Buy me a coffee</span>
                </a>
              </Grid.Column> */}
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
    );
  }
}

BottomSection.propTypes = {
  history: PropTypes.object,
};

export default withRouter(BottomSection);
