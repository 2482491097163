import React from 'react';
import { RotateSpinner } from "react-spinners-kit";

const Loading = () => {
  return (
    <div className="loading-spinner">
      <RotateSpinner color="#f26126" />
    </div>
  );
}
 
export default Loading;