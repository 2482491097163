import React, { Component } from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import { withFirebase } from "react-redux-firebase";
import ImageFadeIn from "react-image-fade-in";

class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { imageRef, firebase } = this.props;
    if (imageRef) {
      const ref = firebase.storage().ref().child(imageRef);
      ref.getDownloadURL().then((url) => {
        console.log(url);
        this.setState({ imageUrl: url });
      });
    }
  }

  render() {
    const {
      title,
      subtitle,
      location,
      room,
      style,
      width,
      height,
    } = this.props;

    return (
      <div className="card" style={style}>
        <div className="card-text">
          <div className="card-title">{title}</div>
          <div className="card-subtitle">{subtitle}</div>
          <div className="card-detail">{`${location} ${
            room ? `-${room}` : ""
          }`}</div>
        </div>
        {this.state.imageUrl ? (
          <ImageFadeIn
            src={this.state.imageUrl}
            height={height}
            width={width}
          />
        ) : (
          <div>
            <Skeleton height={height} width={width} />
          </div>
        )}
      </div>
    );
  }
}

Card.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  style: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  imageRef: PropTypes.string,
  location: PropTypes.string,
  room: PropTypes.string,
  firebase: PropTypes.object,
};

export default withFirebase(Card);
