import React from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";

const RoundButton = ({ className, image, icon, text, height, width, onClick }) => {
  return (
    <div
      className={`round-button ${className}`}
      style={{ height, width }}
      onClick={onClick}
    >
      {image && <img src={image} alt="" />}
      {icon && <Icon name={icon} size="large" />}
      {text && <div>{text}</div>}
    </div>
  );
};

RoundButton.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  height: PropTypes.number,
  width: PropTypes.number,
};

export default RoundButton;
