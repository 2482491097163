import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withFirebase } from "react-redux-firebase";
import { Menu, Button, Image, Icon } from "semantic-ui-react";

import { getImage } from "Actions/storageActions";
import { update } from "Actions/localActions";
import fellaImage from "../../assets/images/fella-full-logo.png";
import User from "FirebaseObj/user";

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: props.leftItems && Object.keys(props.leftItems)[0],
      imageUrl: null,
    };
  }

  componentDidUpdate() {
    const { profile, storage, getImage } = this.props;

    const currentUser = User.fromJson(profile);
    if (storage && currentUser && !storage[currentUser.photoSmall]) {
      getImage(currentUser.photoSmall);
    }
  }

  setActiveTab(tab) {
    this.setState({ activeTab: tab });
  }

  render() {
    const {
      fixed,
      secondary,
      inverted,
      showLogo,
      showActions,
      leftItems,
      history,
      updateLocal,
      profile,
      location,
      storage,
    } = this.props;
    const currentUser = User.fromJson(profile);

    return (
      <Menu
        className="navigation-bar"
        fixed={fixed ? "top" : null}
        inverted={inverted}
        pointing={!fixed}
        secondary={secondary}
        size="small"
      >
        {location.pathname === "/home" ? (
          <Menu.Item
            onClick={() => {
              updateLocal({ showSideBar: true });
            }}
          >
            <Icon size="large" name="sidebar" />
          </Menu.Item>
        ) : null}
        <div className="navigation-header">
          {showLogo ? (
            <a onClick={() => history.push("/")}>
              <Image size="small" src={fellaImage} />
            </a>
          ) : (
            <div style={{ width: 72 }} />
          )}
        </div>
        {leftItems
          ? Object.keys(leftItems).map((tab) => {
              return (
                <Menu.Item
                  key={tab}
                  as="div"
                  active={this.state.activeTab === tab}
                  onClick={() => this.setActiveTab(tab)}
                >
                  {tab}
                </Menu.Item>
              );
            })
          : null}
        <Menu.Item position="right">
          {!currentUser.uid ? (
            <div>
              <Button inverted={!fixed} onClick={() => history.push("/login")}>
                Log in
              </Button>
              {showActions ? (
                <Button as="a" primary style={{ marginLeft: "0.5em" }}>
                  Activate
                </Button>
              ) : null}
            </div>
          ) : (
            <div>
              {currentUser.uid &&
              currentUser.isLeader() &&
              location.pathname !== "/home" ? (
                <Button
                  as="a"
                  primary
                  style={{ marginRight: "1em" }}
                  onClick={() => history.push("/home")}
                >
                  Go to Dashboard
                </Button>
              ) : null}
              <Image
                src={
                  storage[currentUser.photoSmall] ||
                  "https://react.semantic-ui.com/images/wireframe/square-image.png"
                }
                avatar
              />
            </div>
          )}
        </Menu.Item>
      </Menu>
    );
  }
}

NavBar.propTypes = {
  fixed: PropTypes.bool,
  inverted: PropTypes.bool,
  secondary: PropTypes.bool,
  showLogo: PropTypes.bool,
  showActions: PropTypes.bool,
  leftItems: PropTypes.object,
  currentUser: PropTypes.object,
  history: PropTypes.object,
  updateLocal: PropTypes.func,
  getImage: PropTypes.func,
  profile: PropTypes.object,
  location: PropTypes.object,
  firebase: PropTypes.object,
  storage: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    profile: state.firebase.profile,
    storage: state.storage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateLocal: (data) => dispatch(update(data)),
    getImage: (data) => dispatch(getImage(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFirebase(withRouter(NavBar)));
