const initState = {};

const localReducer = (state = initState, action) => {
  switch (action.type) {
    case "UPDATE":
      return Object.assign({}, state, action.data);
    default:
      return state;
  }
};

export default localReducer;
