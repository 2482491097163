import React, { Component } from "react";
import PropTypes from "prop-types";

import { Label, Form } from "semantic-ui-react";

class CustomInput extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { title, type, error, onChange } = this.props;
    return (
      <Form.Field className="custom-input">
        {/* <div className="label">{title}</div> */}
        <Form.Input
          label={title}
          fluid
          error={!!error}
          type={type}
          onChange={onChange}
        />
        {error ? (
          <Label basic color="red" pointing>
            Please enter a value
          </Label>
        ) : null}
      </Form.Field>
    );
  }
}

CustomInput.propTypes = {
  title: PropTypes.string,
  error: PropTypes.any,
  type: PropTypes.string,
  onChange: PropTypes.func,
};

export default CustomInput;
