import React, { Suspense, lazy } from "react";
import PropTypes from "prop-types";
import { render } from "react-dom";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider, useSelector } from "react-redux";
import thunk from "redux-thunk";
import {
  createFirestoreInstance,
  reduxFirestore,
  getFirestore,
} from "redux-firestore";
import {
  ReactReduxFirebaseProvider,
  getFirebase,
  isLoaded,
  isEmpty,
} from "react-redux-firebase";
import {
  firebaseConfig_DEV,
  firebaseConfig_PROD,
} from "./config/firebaseConfig";

import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/messaging";

import rootReducer from "./store/reducers/rootReducer";

import WelcomePage from "./component/layout/WelcomePage";
const HomePage = lazy(() => import("./component/layout/home/HomePage.js"));
const LoginPage = lazy(() => import("./component/layout/LoginPage.js"));
const TermsPage = lazy(() => import("./component/layout/TermsPage.js"));
const FormPage = lazy(() => import("./component/layout/FormPage.js"));
const PrivacyPage = lazy(() => import("./component/layout/PrivacyPage.js"));
const FeedbackPage = lazy(() => import("./component/layout/FeedbackPage.js"));

import { Loading } from "UIComponent";

const IS_DEV = process.env.NODE_ENV === "development";
const firebaseConfig = IS_DEV ? firebaseConfig_DEV : firebaseConfig_PROD;

firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.auth();
firebase.firestore();
firebase.storage();

const store = createStore(
  rootReducer,
  compose(
    reduxFirestore(firebase, {}),
    applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore }))
  )
);

const rrfProps = {
  firebase,
  config: Object.assign(firebaseConfig, {
    userProfile: "users",
    useFirestoreForProfile: true,
  }),
  dispatch: store.dispatch,
  createFirestoreInstance,
};

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated or if auth is not
// yet loaded
function PrivateRoute({ children, ...rest }) {
  const auth = useSelector((state) => state.firebase.auth);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!isLoaded(auth)) {
          return <Loading />;
        }
        const isAuthenticated = isLoaded(auth) && !isEmpty(auth);
        return isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}

PrivateRoute.propTypes = {
  children: PropTypes.object,
};

function PublicRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={() => {
        return children;
      }}
    />
  );
}

PublicRoute.propTypes = {
  children: PropTypes.object,
};

function App() {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route path="/privacy_policy">
          <PrivacyPage />
        </Route>
        <Route path="/terms_and_conditions">
          <TermsPage />
        </Route>
        <Route path="/feedback">
          <FeedbackPage />
        </Route>
        <Route path="/login">
          <LoginPage />
        </Route>
        <PrivateRoute path="/home">
          <HomePage />
        </PrivateRoute>
        <PublicRoute path="/form">
          <FormPage />
        </PublicRoute>
        <PublicRoute exact path="/">
          <WelcomePage />
        </PublicRoute>
      </Switch>
    </Suspense>
  );
}

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("./firebase-messaging-sw.js")
      .then(function (registration) {
        console.log("Registration successful, scope is:", registration.scope);
      })
      .catch(function (err) {
        console.log("Service worker registration failed, error:", err);
      });
  });
}

// We need to add the root here since HtmlWebpackPlugin
// doesn't automatically add a root div
function appComponent() {
  const element = document.createElement("div");
  element.setAttribute("id", "root");
  element.style.height = "100%";
  return element;
}
document.body.appendChild(appComponent());

render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById("root")
);
