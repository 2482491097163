import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";
import authReducer from "./authReducer";
import localReducer from "./localReducer";
import storageReducer from "./storageReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  local: localReducer,
  storage: storageReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
});

export default rootReducer;
