import moment from "moment-timezone";

const DEFAULT_YEAR = 1990;

class User {
  constructor(
    baptized,
    christian,
    email,
    firstName,
    fullName,
    fullNameZh,
    gender,
    initial,
    lastName,
    phone,
    homePhone,
    provider,
    school,
    schoolCategory,
    schoolYear,
    yearAccepted,
    yearBaptized,
    uid,
    userName,
    status,
    language,
    whyJoin,
    service,
    membership,
    photo,
    photoSmall,
    birthday,
    lastActive,
    admin,
    groupInfo,
    createdAt,
    newComerNotification,
    announcementNotification,
    programReminderNotification,
    attendanceReminderNotification
  ) {
    (this.baptized = baptized),
      (this.christian = christian),
      (this.email = email),
      (this.firstName = firstName),
      (this.fullName = fullName),
      (this.fullNameZh = fullNameZh),
      (this.gender = gender),
      (this.initial = initial),
      (this.lastName = lastName),
      (this.phone = phone),
      (this.homePhone = homePhone),
      (this.provider = provider),
      (this.school = school),
      (this.schoolCategory = schoolCategory),
      (this.schoolYear = schoolYear),
      (this.yearAccepted = yearAccepted),
      (this.yearBaptized = yearBaptized),
      (this.uid = uid),
      (this.userName = userName),
      (this.status = status),
      (this.language = language),
      (this.whyJoin = whyJoin),
      (this.service = service),
      (this.membership = membership),
      (this.photo = photo),
      (this.photoSmall = photoSmall),
      (this.birthday = birthday),
      (this.lastActive = lastActive),
      (this.admin = admin),
      (this.groupInfo = groupInfo),
      (this.createdAt = createdAt),
      (this.newComerNotification = newComerNotification),
      (this.announcementNotification = announcementNotification),
      (this.programReminderNotification = programReminderNotification),
      (this.attendanceReminderNotification = attendanceReminderNotification);
  }

  create(group, subGroup) {
    this.admin = false;
    this.groupInfo = {
      group: {
        group: group,
        subGroup: subGroup,
        leader: false,
        committee: false,
        coach: false,
        joinedAt: moment(),
      },
    };
  }

  static fromJson(value) {
    if (value == null) return null;

    return new User(
      value["baptized"],
      value["christian"],
      value["email"],
      value["firstName"],
      value["fullName"],
      value["fullNameZh"],
      value["gender"],
      value["initial"],
      value["lastName"],
      value["phone"],
      value["homePhone"],
      value["provider"],
      value["school"],
      value["schoolCategory"],
      value["schoolYear"],
      value["yearAccepted"],
      value["yearBaptized"],
      value["uid"],
      value["userName"],
      value["status"],
      value["language"],
      value["whyJoin"],
      value["service"],
      value["membership"],
      value["photo"],
      value["photoSmall"],
      value["birthday"]?.toDate() ??
        moment({ year: DEFAULT_YEAR, month: 1, day: 1 }),
      value["lastActive"]?.toDate() ??
        moment({ year: DEFAULT_YEAR, month: 1, day: 1 }),
      value["admin"],
      value["groupInfo"],
      value["createdAt"]?.toDate(),
      value["newComerNotification"],
      value["announcementNotification"],
      value["programReminderNotification"],
      value["attendanceReminderNotification"]
    );
  }

  getSubGroup(grp) {
    return this.groupInfo != null && this.isNotEmpty(this.groupInfo)
      ? this.groupInfo[grp] != null
        ? this.groupInfo[grp]["subGroup"]
        : null
      : null;
  }

  getCoach(grp) {
    return this.groupInfo != null && this.isNotEmpty(this.groupInfo)
      ? this.groupInfo[grp] != null
        ? this.groupInfo[grp]["coach"]
        : false
      : false;
  }

  getLeader(grp) {
    return this.groupInfo != null && this.isNotEmpty(this.groupInfo)
      ? this.groupInfo[grp] != null
        ? this.groupInfo[grp]["leader"]
        : false
      : false;
  }

  getCommittee(grp) {
    return this.groupInfo != null && this.isNotEmpty(this.groupInfo)
      ? this.groupInfo[grp] != null
        ? this.groupInfo[grp]["committee"]
        : false
      : false;
  }

  setCoach(grp, val) {
    this.groupInfo[grp]["coach"] = val;
  }

  setLeader(grp, val) {
    this.groupInfo[grp]["leader"] = val;
  }

  setCommittee(grp, val) {
    this.groupInfo[grp]["committee"] = val;
  }

  setUserRole(grp, role, val) {
    this.groupInfo[grp][role] = val;
  }

  isLeader(grp) {
    const _groups =
      (grp && [grp]) || (this.groupInfo && Object.keys(this.groupInfo));
    return (
      (_groups &&
        _groups.some((g) => this.getLeader(g) || this.isGroupAdmin(g))) ||
      false
    );
  }

  isGroupAdmin(grp) {
    return (
      (this.getCommittee(grp) || this.getCoach(grp) || this.admin) ?? false
    );
  }

  isAnyGroupAdmin() {
    return (
      this.groupInfo != null &&
      this.isNotEmpty(this.groupInfo) &&
      this.groupInfo.keys.any((grp) => this.isGroupAdmin(grp))
    );
  }

  setField(fKey, val) {
    switch (fKey) {
      case "baptized":
        this.baptized = val;
        break;
      case "christian":
        this.christian = val;
        break;
      case "email":
        this.email = val;
        break;
      case "firstName":
        this.firstName = val;
        break;
      case "lastName":
        this.lastName = val;
        break;
      case "fullName":
        this.fullName = val;
        break;
      case "fullNameZh":
        this.fullNameZh = val;
        break;
      case "gender":
        this.gender = val;
        break;
      case "phone":
        this.phone = val;
        break;
      case "homePhone":
        this.phone = val;
        break;
      case "school":
        this.school = val;
        break;
      case "schoolCategory":
        this.schoolCategory = val;
        break;
      case "schoolYear":
        this.schoolYear = val;
        break;
      case "yearAccepted":
        this.yearAccepted = val;
        break;
      case "yearBaptized":
        this.yearBaptized = val;
        break;
      case "uid":
        this.uid = val;
        break;
      case "userName":
        this.userName = val;
        break;
      case "language":
        this.language = val;
        break;
      case "whyJoin":
        this.whyJoin = val;
        break;
      case "service":
        this.service = val;
        break;
      case "membership":
        this.membership = val;
        break;
      case "photo":
        this.photo = val;
        break;
      case "photoSmall":
        this.photoSmall = val;
        break;
    }
  }

  setDateField(fKey, val) {
    switch (fKey) {
      case "birthday":
        this.birthday = val;
        break;
      case "lastActive":
        this.lastActive = val;
        break;
    }
  }

  isNotEmpty(obj) {
    return Object.keys(obj).length > 0;
  }

  toJson() {
    return {
      baptized: this.baptized,
      christian: this.christian,
      email: this.email,
      firstName: this.firstName,
      fullName: this.fullName,
      fullNameZh: this.fullNameZh,
      gender: this.gender,
      initial: this.initial,
      lastName: this.lastName,
      phone: this.phone,
      homePhone: this.homePhone,
      provider: this.provider,
      school: this.school,
      schoolCategory: this.schoolCategory,
      schoolYear: this.schoolYear,
      yearAccepted: this.yearAccepted,
      yearBaptized: this.yearBaptized,
      uid: this.uid,
      userName: this.userName,
      status: this.status,
      language: this.language,
      whyJoin: this.whyJoin,
      service: this.service,
      membership: this.membership,
      photo: this.photo,
      photoSmall: this.photoSmall,
      birthday: this.birthday,
      lastActive: this.lastActive,
      admin: this.admin,
      groupInfo: this.groupInfo,
      newComerNotification: this.newComerNotification,
      announcementNotification: this.announcementNotification,
      programReminderNotification: this.programReminderNotification,
      attendanceReminderNotification: this.attendanceReminderNotification,
    };
  }
}

export default User;
